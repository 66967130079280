import { getLanguage } from "localization/i8intl";
// import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

//import { useRouter } from "next/router";

const NewsTopbar = (props) => {
  const router = useRouter();
  const [language, setLanguage] = useState("");
  useEffect(() => {
    setLanguage(getLanguage());
  }, []);
  const mainUrl = router?.pathname.includes("/video")
    ? "video/"
    : router?.pathname.includes("/text")
    ? "text/"
    : router?.pathname.includes("/audio")
    ? "audio/"
    : props.videoEn === "Top Shows"
    ? "show/all"
    : props.newsEn === "Polls" ||
      props.videoEn === "Shorts" ||
      props.videoEn === "Visual Stories" ||
      props.videoEn === "Top Podcasts"
    ? ""
    : "all/";

  console.log(
    "aaaaa",
    `/${mainUrl}${
      props.videoEn === "Shorts"
        ? "short-video"
        : props.videoEn === "Visual Stories"
        ? "visualstories"
        : props.videoEn === "Top Shows"
        ? ""
        : props?.categoryInfo?.slug ||
          props?.categoryInfo?.name.toLowerCase() ||
          props?.newsEn?.toLowerCase()
    }  `
  );

  //console.log("props.newsE", mainUrl, props.videoEn);

  //const router = useRouter()
  // console.log("title==", props?.newsHn);
  // console.log("title1", props?.categoryInfo?.name);
  // console.log("title11", props?.categoryInfo?.nameHn);
  // console.log("title2", props?.videoHn);
  // console.log("title3", props?.newsEn);
  // const [videoShowClass, setVideoShowClass] = useState("");

  // console.log("newwews", props?.newsBgClass);
  // if (props?.newsBgClass === "videoShowTopBar")
  //   setVideoShowClass("videoShowTopBar");

  return (
    <>
      <div
        className={`news-top-bar ${props?.newsBgClass}`}
        // style={{
        //   background:
        //     props?.newsBgclassName === "show" ||
        //     props?.newsBgclassName === "videoShow"
        //       ? "#FFFFFF"
        //       : props?.newsBgclassName === "topEpisode"
        //       ? "#6FCBD0"
        //       : `#${props?.categoryInfo?.headerColor || "61C777"}`,
        // }}
        style={{
          backgroundColor: "white",
        }}
      >
        <a
          href={`/${mainUrl}${
            props.videoEn === "Shorts"
              ? "short-video"
              : props.videoEn === "Visual Stories"
              ? "visualstories"
              : props.videoEn === "Top Shows"
              ? ""
              : props.videoEn === "Top Podcasts"
              ? "audio"
              : props?.categoryInfo?.slug ||
                props?.categoryInfo?.name.toLowerCase() ||
                props?.newsEn?.toLowerCase()
          }  `}
        >
          {" "}
          <div
            className={`news-title-left ${props?.videoTitleClass}`}
            style={{
              color:
                props.newsEn === "Polls"
                  ? "#EB0E8C"
                  : props.videoEn === "Shorts" ||
                    props.videoEn === "Visual Stories" ||
                    props.videoEn === "Top Podcasts"
                  ? "#EB0E8C"
                  : props?.videoEn === "Live Updates"
                  ? "#6FCBD0"
                  : props?.newsEn === "Top Shows"
                  ? "#EB0E8C"
                  : `#${props?.categoryInfo?.headerColor || "61C777"}`,
            }}
          >
            {props?.newsEn !== "Live Updates" &&
              (props?.categoryInfo?.image ? (
                <img
                  style={{
                    paddingRight: "10px",
                    color: `#${props?.categoryInfo?.headerColor || "61C777"}`,
                  }}
                  src={props?.categoryInfo?.image}
                  // src={"/assets/images/Entertainment.png"}
                  alt="img"
                  loading="lazy"
                  width="32px"
                  height="32px"
                />
              ) : props.newsIcon === "none" ? (
                <></>
              ) : props?.newsIcon === "videoShow" ? (
                <i className="icon-play-circle"></i>
              ) : props.newsIcon === "shorts" ? (
                <img
                  src="/assets/images/Group 351.svg"
                  style={{
                    paddingRight: "10px",
                  }}
                  width="32px"
                  height="32px"
                  loading="lazy"
                  alt="home"
                />
              ) : props.newsIcon === "podcast-wid" ? (
                <img
                  src="https://static.thelallantop.com/images/post/1720689949626_vector_(8).webp"
                  // style={{
                  //   paddingRight: "10px",
                  // }}
                  width="38px"
                  height="32px"
                  loading="lazy"
                  alt="home"
                />
              ) : props.newsIcon === "visual-story" ? (
                <img
                  src="https://static.thelallantop.com/images/post/1719816698725_vector_(4).webp"
                  style={{
                    paddingRight: "10px",
                  }}
                  width="32px"
                  height="32px"
                  loading="lazy"
                  alt="home"
                />
              ) : (
                <i className="icon-news"></i>
              ))}
            {props?.newsEn === "Live Updates" && (
              <img
                style={{
                  paddingRight: "10px",
                }}
                src="/assets/images/live_gif.gif"
                height="32px"
                loading="lazy"
                width="32px"
                // src={"/assets/images/Entertainment.png"}
                alt="img"
              />
            )}
            <h2>
              {props?.newsEn === "Live Updates"
                ? language === "hi"
                  ? props?.newsHn
                  : props?.newsEn
                : language === "hi"
                ? props?.categoryInfo?.nameHn || props?.videoHn || props?.newsHn
                : props?.categoryInfo?.name || props?.videoEn || props?.newsEn}
            </h2>
          </div>
        </a>

        {props?.newsHn === "featured" ||
        props?.newsHn === "फीचर्ड" ||
        props?.newsHn === "Top Show" ||
        props?.newsHn === "Featured" ||
        props?.newsHn === "टॉप शो" ||
        props?.newsHn === "फीचर्ड" ? (
          ""
        ) : props?.newsIcon === "videoShow" ? (
          <a
            className={`news-link ${props?.videoLinkClass}`}
            href={`/show/all`}
            style={{
              color: props?.newsBgclassName === "videoShow" && "#5F5F5F",
              marginTop: "0.6rem",
            }}
          >
            <span>
              <FormattedMessage id="see_more" defaultMessage="और देखें" />
            </span>
            <i
              className="icon-arrow-right"
              style={{
                color: "gray",
              }}
            ></i>
          </a>
        ) : props?.newsIcon === "shorts" ? (
          <>
            {" "}
            <a
              className={`news-link ${props?.videoLinkClass}`}
              href={`/short-video`}
              style={{
                color: props?.newsBgclassName === "videoShow" && "#5F5F5F",
                marginTop: "0.6rem",
              }}
            >
              <span>
                <FormattedMessage id="see_more" defaultMessage="और देखें" />
              </span>
              <i
                className="icon-arrow-right"
                style={{
                  color: "gray",
                }}
              ></i>
            </a>
          </>
        ) : props?.newsIcon === "visual-story" ? (
          <>
            {" "}
            <a
              className={`news-link ${props?.videoLinkClass}`}
              href={`/visualstories`}
              style={{
                color: props?.newsBgclassName === "videoShow" && "#5F5F5F",
                marginTop: "0.6rem",
              }}
            >
              <span>
                <FormattedMessage id="see_more" defaultMessage="और देखें" />
              </span>
              <i
                className="icon-arrow-right"
                style={{
                  color: "gray",
                }}
              ></i>
            </a>
          </>
        ) : props?.newsIcon === "podcast-wid" ? (
          <>
            {" "}
            <a
              className={`news-link ${props?.videoLinkClass}`}
              href={`/audio`}
              style={{
                color: props?.newsBgclassName === "videoShow" && "#5F5F5F",
                marginTop: "0.6rem",
              }}
            >
              <span>
                <FormattedMessage id="see_more" defaultMessage="और देखें" />
              </span>
              <i
                className="icon-arrow-right"
                style={{
                  color: "gray",
                }}
              ></i>
            </a>
          </>
        ) : (
          props?.newsEn !== "Live Updates" && (
            <a
              className={`news-link ${props?.videoLinkClass}`}
              href={`/${mainUrl}/${
                props?.categoryInfo?.slug ||
                props?.categoryInfo?.name.toLowerCase() ||
                props?.newsEn?.toLowerCase()
              }`}
              style={{
                color: props?.newsBgclassName === "videoShow" && "#5F5F5F",
              }}
            >
              <span style={{ color: "#5F5F5F" }}>
                <FormattedMessage id="see_more" defaultMessage="और देखें" />
              </span>
              <i
                className="icon-arrow-right"
                style={{
                  color: "gray",
                }}
              ></i>
            </a>
          )
        )}
      </div>
    </>
  );
};
export default NewsTopbar;
