import React from "react";
import dynamic from "next/dynamic";
import { useEffect, useState } from "react";
import { useRouter } from "next/router.js";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useWindowSize } from "api/commonConstants";
import ShortsSlider from "components/short-video/shortSlider.js";
import RestCategory from "./restCategory.js";

const Entertainment = dynamic(() =>
  import("./entertainment.js", { ssr: false })
);
const FirstCategory = dynamic(() =>
  import("./firstCategory.js", { ssr: true })
);

// const RestCategory = dynamic(() => import("./restCategory.js", { ssr: true }));
const VisualStories = dynamic(() =>
  import("./visualStories.js", { ssr: false })
);

const CategoryPosts = ({
  NewsPageData = [],
  shortsData = [],
  topShowsWidgetData = [],
  topPodcastData = [],
  authorWidgetData = [],
}) => {
  const dispatch = useDispatch();
  const size = useWindowSize();

  // const isMobile = size.width < 500;
  const isDesktop = size.width >= 500;
  const router = useRouter();
  useEffect(() => {
    fetchCategoryPosts();
  }, []);

  const fetchCategoryPosts = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    // console.log("Fetchcalled");
    await fetch(
      `${process.env.NEXT_PUBLIC_APP_API_URL}/v2/web/home?type=${
        router.pathname === "/"
          ? "video,text,liveblog"
          : router.pathname === "/text"
          ? "text"
          : "video"
      }&limit=12&skip=1&accessType=web`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        // const resArray = [...shortData, ...data?.result?.entities];
        // console.log("resArray", resArray, data?.result?.entities);
        // setShortData(resArray);
        dispatch({
          type: "HOME_CATEGORY_POST",
          //payload: { _id },
          payload: { categoryPosts: data?.result?.categoryPosts },
          //payload: { payload: { _id } },
        });
      });
  };
  const categoryPosts = useSelector(
    (data) => data.PostReducer.allCategoryHomePosts
  );
  const [firstScroll, setFirstScroll] = useState(false);

  useEffect(() => {
    if (firstScroll === false) {
      function handleScroll() {
        setFirstScroll(true);
        window.removeEventListener("scroll", handleScroll);
      }

      window.addEventListener("scroll", handleScroll);
    }
  }, [firstScroll]);
  return (
    <>
      <FirstCategory
        categoryInfo={categoryPosts && categoryPosts[0]?.categoryInfo}
        newsData={categoryPosts && categoryPosts[0]?.entities}
        topShowsWidgetData={topShowsWidgetData}
        topPodcastData={topPodcastData}
      />
      {!router.pathname.includes("/text") && (
        <ShortsSlider
          shortsData={shortsData}
          authorWidgetData={authorWidgetData}
        />
      )}

      {categoryPosts?.slice(1).map((category, _) => {
        return (
          <>
            {(
              <RestCategory
                categoryInfo={category?.categoryInfo}
                newsData={category?.entities}
              ></RestCategory>
            )}

            {/* {category.categoryInfo.name === "Entertainment" &&
                (isDesktop ? (
                  <Entertainment
                    categoryInfo={category?.categoryInfo}
                    newsData={category?.entities}
                  ></Entertainment>
                ) : (
                  <RestCategory
                    categoryInfo={category?.categoryInfo}
                    newsData={category?.entities}
                  ></RestCategory>
                ))} */}
          </>
        );
      })}
    </>
  );
};

export default CategoryPosts;
